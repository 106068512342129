<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="delinquents"
      :single-select="singleSelect"
      :items-per-page="delinquentsPerPage"
      :server-items-length="totalDelinquents"
      :options.sync="options"
      @page-count="pageCount = $event"
      show-select
      class="elevation-1"
      :search="decision"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar extended flat color="white">
          <v-col cols="12" sm="4">
            <!-- <p class="mt-8 ml-6">Filter by  </p> -->
            <v-select
              :items="decisions"
              item-text="name"
              item-value="id"
              v-model="decision"
              dense
              color="grey"
              label="Decision"
              hint="Filter by Decision"
              persistent-hint
              class="mt-8 mx-4"
              solo
              v-if="delinquents.length > 0"
            />
          </v-col>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-tabs height="40" class="mt-4">
            <v-tab class="btn" @click="makeIdParam"> Admitted </v-tab>
            <v-tab class="btn" @click="delinquents = []">
              Rehabilitation
            </v-tab>
          </v-tabs>
          <!-- <v-btn tile link small outlined class="green btn">Admitted</v-btn>
          <v-btn tile link small outlined class="btn">Rehabilitation</v-btn> -->
          <v-spacer></v-spacer>
          <v-btn rounded disabled right>{{ currentCenter.name }}</v-btn>
          <v-dialog v-model="dialog" max-width="1200px">
            <v-card>
              <v-row>
                <v-spacer />
                <v-col cols="1">
                  <v-btn
                    class="ml-0"
                    dark
                    icon
                    color="pink"
                    large
                    @click="dialog = false"
                    ><v-icon>mdi-close-circle</v-icon></v-btn
                  >
                </v-col>
              </v-row>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-card class="mt-2" max-width="1200" outlined raised>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <p>I. Identification</p>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="First Name"
                              type="text"
                              outlined
                              v-model="editedDelinquent.firstName"
                              color="grey"
                              class="mt-n6"
                              disabled
                              filled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Last Name"
                              type="text"
                              outlined
                              v-model="editedDelinquent.lastName"
                              color="grey"
                              class="mt-n6"
                              disabled
                              filled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Gender"
                              outlined
                              v-model="editedDelinquent.gender"
                              class="mt-n6"
                              color="grey"
                              disabled
                              filled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Marital Status"
                              outlined
                              v-model="editedDelinquent.maritalStatus"
                              class="mt-n6"
                              color="grey"
                              disabled
                              filled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Father Name"
                              v-model="editedDelinquent.fatherName"
                              type="text"
                              outlined
                              name="Father Name"
                              color="grey"
                              class="mt-n6"
                              disabled
                              filled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Mother Name"
                              type="text"
                              outlined
                              v-model="editedDelinquent.motherName"
                              name="Mother Name"
                              color="grey"
                              class="mt-n6"
                              disabled
                              filled
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card class="mt-2" max-width="1200" outlined raised>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <p>II. Place and Date of Birth</p>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="birthProvinces"
                              item-text="name"
                              item-value="id"
                              v-model="
                                editedDelinquent.placeOfBirth.province.id
                              "
                              dense
                              color="grey"
                              label="Province"
                              outlined
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="birthDistricts"
                              item-text="name"
                              item-value="id"
                              v-model="
                                editedDelinquent.placeOfBirth.district.id
                              "
                              dense
                              color="grey"
                              label="District"
                              outlined
                              class="mt-n6"
                              disabled
                              filled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="birthSectors"
                              item-text="name"
                              item-value="id"
                              v-model="editedDelinquent.placeOfBirth.sector.id"
                              dense
                              color="grey"
                              label="Sector"
                              outlined
                              class="mt-n6"
                              disabled
                              filled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="birthCells"
                              item-text="name"
                              item-value="id"
                              v-model="editedDelinquent.placeOfBirth.cell.id"
                              dense
                              color="grey"
                              label="Cell"
                              outlined
                              class="mt-n6"
                              disabled
                              filled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="birthVillages"
                              item-text="name"
                              item-value="id"
                              v-model="editedDelinquent.placeOfBirth.village.id"
                              dense
                              color="grey"
                              label="Village"
                              outlined
                              class="mt-n6"
                              disabled
                              filled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              v-model="editedDelinquent.dateOfBirth"
                              label="Date of Birth"
                              outlined
                              filled
                              disabled
                              color="grey"
                              class="mt-n6"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card max-width="1200" outlined raised class="mt-2">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <p>III. Place of Residence</p>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="residenceProvinces"
                              item-text="name"
                              item-value="id"
                              v-model="
                                editedDelinquent.apprehension
                                  .delinquentResidence.province.id
                              "
                              dense
                              color="grey"
                              label="Province"
                              outlined
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="residenceDistricts"
                              item-text="name"
                              item-value="id"
                              v-model="
                                editedDelinquent.apprehension
                                  .delinquentResidence.district.id
                              "
                              dense
                              color="grey"
                              label="District"
                              outlined
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="residenceSectors"
                              item-text="name"
                              item-value="id"
                              v-model="
                                editedDelinquent.apprehension
                                  .delinquentResidence.sector.id
                              "
                              dense
                              color="grey"
                              label="Sector"
                              outlined
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="residenceCells"
                              item-text="name"
                              item-value="id"
                              v-model="
                                editedDelinquent.apprehension
                                  .delinquentResidence.cell.id
                              "
                              dense
                              color="grey"
                              label="Cell"
                              outlined
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="residenceVillages"
                              item-text="name"
                              item-value="id"
                              v-model="
                                editedDelinquent.apprehension
                                  .delinquentResidence.village.id
                              "
                              dense
                              color="grey"
                              label="Village"
                              outlined
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card max-width="1200" outlined raised class="mt-2">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <p>IV. Place of Apprehension</p>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="apprehensionProvinces"
                              item-text="name"
                              item-value="id"
                              v-model="
                                editedDelinquent.apprehension.apprehensionPlace
                                  .province.id
                              "
                              dense
                              color="grey"
                              label="Province"
                              outlined
                              name="Apprehension Province"
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="apprehensionDistricts"
                              item-text="name"
                              item-value="id"
                              v-model="
                                editedDelinquent.apprehension.apprehensionPlace
                                  .district.id
                              "
                              dense
                              color="grey"
                              label="District"
                              outlined
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="apprehensionSectors"
                              item-text="name"
                              item-value="id"
                              v-model="
                                editedDelinquent.apprehension.apprehensionPlace
                                  .sector.id
                              "
                              dense
                              color="grey"
                              label="Sector"
                              outlined
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="apprehensionCells"
                              item-text="name"
                              item-value="id"
                              v-model="
                                editedDelinquent.apprehension.apprehensionPlace
                                  .cell.id
                              "
                              dense
                              color="grey"
                              label="Cell"
                              outlined
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                              :items="apprehensionVillages"
                              item-text="name"
                              item-value="id"
                              v-model="
                                editedDelinquent.apprehension.apprehensionPlace
                                  .village.id
                              "
                              dense
                              color="grey"
                              label="Village"
                              outlined
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card width="1200" outlined raised class="mt-2">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <p>
                              V. How he/she was apprehended and act he/she was
                              doing
                            </p>
                          </v-col>
                          <v-col cols="12" sm="12">
                            <v-text-field
                              dense
                              label="How he/she was apprehended and act he/she was doing"
                              type="text"
                              outlined
                              v-model="
                                editedDelinquent.apprehension.apprehensionWay
                              "
                              color="grey"
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Deviant Acts"
                              type="text"
                              outlined
                              v-model="
                                editedDelinquent.apprehension.deviantActs
                              "
                              name="Last Name"
                              color="grey"
                              class="mt-n6"
                              disabled
                              filled
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card max-width="1200" outlined raised class="mt-2">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <p>Additional Details</p>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Specific Disease"
                              type="text"
                              outlined
                              v-model="editedDelinquent.specificDisease"
                              color="grey"
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Next Of Kin Phone"
                              type="text"
                              outlined
                              v-model="editedDelinquent.guardianPhone"
                              color="grey"
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Homeless Status"
                              v-model="editedDelinquent.homeless"
                              color="grey"
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Father Vital Status"
                              outlined
                              v-model="editedDelinquent.fatherVitalStatus"
                              color="grey"
                              filled
                              class="mt-n6"
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Mother Vital Status"
                              outlined
                              v-model="editedDelinquent.motherVitalStatus"
                              color="grey"
                              filled
                              class="mt-n6"
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Education"
                              outlined
                              v-model="editedDelinquent.educationLevel"
                              color="grey"
                              filled
                              class="mt-n6"
                              disabled
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card width="1200" outlined raised class="mt-2">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <p>VI. Decision Taken</p>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              v-model="
                                editedDelinquent.latestScreening.meetingDate
                              "
                              label="Meeting Date"
                              outlined
                              filled
                              color="grey"
                              class="mt-n6"
                              dense
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Decision taken"
                              outlined
                              v-model="
                                editedDelinquent.latestScreening.decision
                              "
                              color="grey"
                              filled
                              class="mt-n6"
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              dense
                              label="Select"
                              outlined
                              v-model="
                                editedDelinquent.latestScreening.transferCenter
                              "
                              color="grey"
                              filled
                              class="mt-n6"
                              v-if="
                                editedDelinquent.latestScreening
                                  .transferCenter !== null
                              "
                              disabled
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card width="1200" outlined raised class="mt-2">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <p>VII. Reason for decision taken</p>
                          </v-col>
                          <v-col cols="12" sm="12">
                            <v-text-field
                              dense
                              label="Reason for decision taken"
                              type="text"
                              outlined
                              v-model="
                                editedDelinquent.latestScreening.decisionReason
                              "
                              color="grey"
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card width="1200" outlined raised class="mt-2">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <p>
                              VIII. Opinion of the person received in transit
                              center, his/her parent or next of kin
                            </p>
                          </v-col>
                          <v-col cols="12" sm="12">
                            <v-text-field
                              dense
                              label="Apprehendee opinion"
                              type="text"
                              outlined
                              v-model="
                                editedDelinquent.latestScreening
                                  .delinquentPointOfView
                              "
                              color="grey"
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="12">
                            <v-text-field
                              dense
                              label="Parent/Next Of Kin opinion"
                              type="text"
                              outlined
                              v-model="
                                editedDelinquent.latestScreening
                                  .guardianPointOfView
                              "
                              color="grey"
                              class="mt-n6"
                              filled
                              disabled
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card width="1200" outlined raised class="mt-2">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <p>IX. MedicalTests</p>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <p class="ml-6">{{ medicalTest1 }} :</p>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              label="Test Result"
                              outlined
                              v-model="medicalTest1Result"
                              color="grey"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4" class="mt-n8">
                            <p class="ml-6">{{ medicalTest2 }} :</p>
                          </v-col>
                          <v-col cols="12" sm="6" class="mt-n8">
                            <v-text-field
                              dense
                              label="Test Result"
                              outlined
                              v-model="medicalTest2Result"
                              color="grey"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4" class="mt-n8">
                            <p class="ml-6">{{ medicalTest3 }} :</p>
                          </v-col>
                          <v-col cols="12" sm="6" class="mt-n8">
                            <v-text-field
                              dense
                              label="Mental Health Status"
                              type="text"
                              outlined
                              v-model="medicalTest3Result"
                              color="grey"
                              filled
                              disabled
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card width="1200" outlined raised class="mt-2">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <p>X. Screening Resolutions</p>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <p class="ml-6">Decision :</p>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              label="Test Result"
                              outlined
                              v-model="
                                editedDelinquent.latestTransfer.eligibilityState
                              "
                              color="grey"
                              filled
                              disabled
                            />
                          </v-col>
                          <v-col cols="12" sm="4" class="mt-n8">
                            <p class="ml-6">Comment :</p>
                          </v-col>
                          <v-col cols="12" sm="6" class="mt-n8">
                            <v-text-field
                              dense
                              label="Comment"
                              type="text"
                              outlined
                              v-model="
                                editedDelinquent.latestTransfer
                                  .eligibilityComment
                              "
                              color="grey"
                              filled
                              disabled
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="viewDelinquent(item)">
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="approveDialog" hide-overlay max-width="430px" persistent>
      <v-card tile outlined>
        <v-card-text>
          <v-menu
            ref="releaseDateMenu"
            v-model="releaseDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formattedReleaseDate"
                label="Transfer Date"
                outlined
                filled
                color="grey"
                dense
                v-if="selectDate"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="releaseDate"
              :max="new Date().toISOString().substr(0, 10)"
              min="1900-01-01"
              no-title
              @change="saveReleaseDate"
            ></v-date-picker>
          </v-menu>
          <p v-if="!selectDate">
            Are you sure you want to submit selected delinquents?
          </p>
          <v-btn
            link
            small
            color="blue"
            @click="selectDate = false"
            :disabled="disableRequest"
            v-if="selectDate"
            >Confirm</v-btn
          >
          <v-btn
            link
            small
            color="blue"
            @click="approveDialog = false"
            :disabled="disableRequest"
            v-if="selectDate"
            >Cancel</v-btn
          >
          <v-btn
            link
            small
            color="blue"
            @click="confirmRelease"
            :disabled="disableRequest"
            v-if="!selectDate"
            >Yes</v-btn
          >
          <v-btn
            link
            small
            color="blue"
            @click="approveDialog = false"
            :disabled="disableRequest"
            v-if="!selectDate"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectDelinquentsPerPage"
            v-model="delinquentsPerPage"
            label="Delinquents per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="8">
          <v-pagination
            v-model="page"
            :length="totalPages"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
        <v-col cols="12" sm="2"> {{ totalDelinquents }} Records </v-col>
      </v-row>
    </div>
    <v-btn
      color="green"
      dark
      class="ml-4 mt-4"
      @click="approvingDialog()"
      v-if="showApprove && delinquents.length > 0"
      >Confirm Release</v-btn
    >
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Delinquent from '@/models/Delinquent'
export default {
  data: () => ({
    privileges: null,
    dialog: false,
    approveDialog: false,
    showApprove: false,
    disableRequest: false,
    delinquents: [],
    options: {
      page: null,
      itemsPerPage: null
    },
    selectDelinquentsPerPage: [2, 5, 10, 15, 20, 25, 30],
    totalPages: 0,
    page: 1,
    pageCount: 0,
    delinquentsPerPage: 20,
    totalDelinquents: 0,
    decisionsObjects: [],
    decisions: [],
    decisionIdParam: null,
    decision: '',
    approvalComment: null,
    singleSelect: false,
    selected: [],
    headers: [
      {
        text: 'Names',
        align: 'start',
        sortable: true,
        value: 'names'
      },
      { text: 'Apprehension Reasons', value: 'apprehension.deviantActs' },
      { text: 'Admission Date', value: 'recordDate' },
      { text: 'Release Reason', value: 'latestScreening.decision' },
      { text: 'Decision Date', value: 'latestScreening.meetingDate' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    medicalTest1: 'Medical Test 1 (HIV/SIDA)',
    medicalTest2: 'Medical Test 2 (TB)',
    medicalTest3: 'Mental Health Status',
    medicalTest1Result: null,
    medicalTest2Result: null,
    medicalTest3Result: null,
    birthProvinces: [],
    apprehensionProvinces: [],
    residenceProvinces: [],
    birthDistricts: [],
    apprehensionDistricts: [],
    residenceDistricts: [],
    birthSectors: [],
    apprehensionSectors: [],
    residenceSectors: [],
    birthCells: [],
    apprehensionCells: [],
    residenceCells: [],
    birthVillages: [],
    apprehensionVillages: [],
    residenceVillages: [],
    editedDelinquent: new Delinquent(),
    releaseDate: new Date().toISOString(),
    releaseDateMenu: false,
    selectDate: true
  }),

  computed: {
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'search',
      'currentCenter',
      'storeProvinces',
      'storeProvincesError'
    ]),

    formattedReleaseDate () {
      return this.releaseDate
        ? this.$moment(this.releaseDate).format('DD-MMM-YYYY')
        : this.retrievedReleaseDate
          ? this.$moment(this.retrievedReleaseDate).format('DD-MMM-YYYY')
          : ''
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.loadDelinquents()
      }
    },
    delinquentsPerPage: {
      handler () {
        this.page = 1
        this.loadDelinquents()
      }
    },
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  mounted () {
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },

  created () {
    this.makeIdParam()
    this.loadBirthProvinces()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapActions('store', ['loadStoreProvinces']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    loadDelinquents () {
      const pageNumber = this.page - 1
      this.$delinquentService
        .fetchAllTransfers(
          'deviant_acts',
          true,
          'APPROVED',
          this.decisionIdParam,
          'ADMITTED',
          pageNumber,
          this.delinquentsPerPage
        )
        .then(response => {
          if (response.content) {
            this.delinquents = response.content
            this.delinquents.forEach(element => {
              element.names =
                element.delinquent.firstName +
                ' ' +
                element.delinquent.lastName
              element.recordDate = this.$moment(element.recordDate).format(
                'DD-MMM-YYYY'
              )
            })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },
    makeIdParam () {
      this.$delinquentService.fetchDecisionsObjects().then(response => {
        const decisionsObject = response.content.filter(
          decision =>
            decision.name ===
              'Hand over the person to the investigation service' ||
            decision.name ===
              'Return the person to the family of origin through the district of origin' ||
            decision.name ===
              'Take the person to a medical facility for needed treatment'
        )
        this.decisionIdParam =
          decisionsObject[0].id +
          ',' +
          decisionsObject[1].id +
          ',' +
          decisionsObject[2].id
        this.decisions = [
          decisionsObject[0].name,
          decisionsObject[1].name,
          decisionsObject[2].name
        ]
        this.decisions.unshift('')
        this.loadDelinquents()
      })
    },
    updateDelinquents () {
      this.selected.forEach(selectedDelinquent => {
        const index = this.delinquents.indexOf(selectedDelinquent)
        if (index > -1) {
          this.delinquents.splice(index, 1)
        }
      })
    },
    confirmRelease () {
      this.disableRequest = true
      this.selected.forEach(element => {
        element.exitDate = this.formattedReleaseDate
      })
      this.$delinquentService
        .confirmTransfer(this.selected, true)
        .then(response => {
          if (!response.status) {
            this.disableRequest = false
            this.updateDelinquents()
            this.approveDialog = false
            this.selected = []
            this.setSnackbar(true)
            this.setColor('green')
            this.setText('Delinquents are released successfully')
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
            this.disableRequest = false
          }
        })
    },
    viewDelinquent (item) {
      this.$delinquentService
        .loadDelinquentIdentification(
          item.delinquent.id,
          'latest_apprehension,general_screening,medical_screening'
        )
        .then(response => {
          if (response.id) {
            this.editedDelinquent = response
            const [
              medicalTest1Result,
              medicalTest2Result,
              medicalTest3Result
            ] = Object.values(this.editedDelinquent.latestMedicalTestResults)
            this.medicalTest1Result = medicalTest1Result
            this.medicalTest2Result = medicalTest2Result
            this.medicalTest3Result = medicalTest3Result
            // this.retrievedDateOfBirth = response.dateOfBirth
            // this.retrievedReleaseDate = response.latestScreening.releaseDate
            this.fetchBirthSiblings()
            this.fetchApprehensionSiblings()
            this.fetchResidenceSiblings()
            // this.webcamPicture = response.picture
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.dialog = true
    },
    approvingDialog () {
      if (this.selected.length > 0) {
        this.approveDialog = true
      } else {
        this.setSnackbar(true)
        this.setColor('orange')
        this.setText('No delinquents selected')
      }
    },
    loadBirthProvinces () {
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else this.reloadStoreProvinces()
    },
    async reloadStoreProvinces () {
      await this.loadStoreProvinces
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('Provinces: ' + this.storeProvincesError)
      }
    },
    fetchBirthSiblings () {
      this.$locationService
        .fetchAllSiblings(this.editedDelinquent.placeOfBirth)
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.birthDistricts,
              this.birthSectors,
              this.birthCells,
              this.birthVillages
            ] = response
          }
        })
    },
    fetchApprehensionSiblings () {
      this.$locationService
        .fetchAllSiblings(this.editedDelinquent.apprehension.apprehensionPlace)
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.apprehensionDistricts,
              this.apprehensionSectors,
              this.apprehensionCells,
              this.apprehensionVillages
            ] = response
          }
        })
    },
    fetchResidenceSiblings () {
      this.$locationService
        .fetchAllSiblings(
          this.editedDelinquent.apprehension.delinquentResidence
        )
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.residenceDistricts,
              this.residenceSectors,
              this.residenceCells,
              this.residenceVillages
            ] = response
          }
        })
    },
    saveReleaseDate (releaseDate) {
      this.$refs.releaseDateMenu.save(releaseDate)
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_TRANSFERS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showApprove = true)
        : (this.showApprove = false)
    }
  }
}
</script>
<style scoped>
.btn {
  text-transform: none;
}
</style>
